enum LogLevel {
  RED = '\x1B[31m',
  GREEN = '\x1B[32m',
  YELLOW = '\x1B[33m',
  BLUE = '\x1B[34m',
  MAGENTA = '\x1B[35m',
  CYAN = '\x1B[36m',
  RESET = '\x1B[0m',
}

type LogLevels = keyof typeof LogLevel;

export function wrapLog(label: string, args: any, logLevel: LogLevels) {
  /* eslint-disable no-console */
  console.log('', LogLevel[logLevel] + '-------' + LogLevel.RESET);
  console.log(LogLevel[logLevel] + label, ...args);
  console.log(LogLevel[logLevel] + '-------\n' + LogLevel.RESET);
  /* eslint-enable no-console */
}

export function wrapError(label: string, ctsEnv = 'production', ...vals: any[]) {
  if (ctsEnv === 'production') return;
  // eslint-disable-next-line no-console
  console.error(LogLevel.RED + label, ...vals, LogLevel.RED, '------------------', LogLevel.RESET);
}
