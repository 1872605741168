import { useRootStore } from '~/store';

export function useYotpoRegion() {
  try {
    const rootStore = useRootStore();
    const yotpoKey = rootStore.yotpoAppKey;
    useHead({
      script: [
        {
          hid: 'yotpo',
          src: `https://staticw2.yotpo.com/${yotpoKey}/widget.js`,
          async: true,
        },
      ],
      link: [
        {
          rel: 'preload',
          as: 'script',
          href: `https://staticw2.yotpo.com/${yotpoKey}/widget.js`,
        },
      ],
    });
  } catch (error) {
    console.error('Error in useYotpoRegion'); // eslint-disable-line no-console
    console.error(error); // eslint-disable-line no-console
  }
}
