import { useProductDataStore } from '~/store/product-data';

export async function useLmbCommonData(i18n: any) {
  try {
    // TODO - check what is best behavior for this function/store usage
    // if (process.server) return;
    const commonDataStore = useProductDataStore();
    const { localeProperties } = i18n;
    const numberFormat = i18n.getNumberFormat(localeProperties.value.code)?.currency;
    const currencyCode = numberFormat?.currency || '';

    const { storeHash, regionCode } = localeProperties.value;

    const res = await $fetch<{
      variants: any[];
      priceListRecords: any[];
      priceListAssignments: any[];
    }>('/api/bc/lmb-nxint', {
      method: 'GET',
      query: {
        storeHash,
        regionCode,
        currencyCode,
      },
    });
    const { variants, priceListRecords, priceListAssignments } = res;

    commonDataStore.initialSetup({ variants, priceListRecords, priceListAssignments });
  } catch (error) {
    console.error('Error in common data setup'); // eslint-disable-line no-console
    console.error(error); // eslint-disable-line no-console
  }
}
