import { get } from 'lodash-es';
import { RegionEnvSettings } from '#region-envs/utils';

export const getRegionEnvSettings = (
  key: keyof RegionEnvSettings['publicRegionSettings'],
  regionCode: string,
  regionSettings: RegionEnvSettings['publicRegionSettings'] | RegionEnvSettings['regionSettings']
) => {
  const keyValue = get(regionSettings, `${regionCode}.${key}`);
  return keyValue as string | undefined;
};
